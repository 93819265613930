// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-consulting-js": () => import("./../../../src/pages/services/consulting.js" /* webpackChunkName: "component---src-pages-services-consulting-js" */),
  "component---src-pages-services-development-js": () => import("./../../../src/pages/services/development.js" /* webpackChunkName: "component---src-pages-services-development-js" */),
  "component---src-pages-services-learning-pods-js": () => import("./../../../src/pages/services/learning-pods.js" /* webpackChunkName: "component---src-pages-services-learning-pods-js" */),
  "component---src-pages-who-we-are-js": () => import("./../../../src/pages/who-we-are.js" /* webpackChunkName: "component---src-pages-who-we-are-js" */)
}

